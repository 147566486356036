<!--
 * @Description:已完成工单详情 completedWorkDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-23 15:36:45
 * @LastEditors: linf
-->
<template>
  <div class='completedWorkDetails-mainBody'>
    <van-nav-bar title="已完成工单详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickBack" />
    <!-- 设备信息 -->
    <van-row class="bigRowOne">
      <van-row class="lineTitle">
        <van-row class="lineTitle-left"></van-row>
        <van-row class="lineTitle-right">设备信息</van-row>
      </van-row>
      <van-row class="lineRow"></van-row>
      <van-row class="lineRowItem">
        <span class="lineRowItem-title">设备类型</span>
        <span class="lineRowItem-content">{{deviceType? deviceType:''}}</span>
      </van-row>
      <van-row class="lineRowItem">
        <span class="lineRowItem-title">设备名称</span>
        <span class="lineRowItem-content">{{deviceInfo? deviceInfo.device_name:''}}</span>
      </van-row>
      <van-row class="lineRowItem">
        <span class="lineRowItem-title">设备编号</span>
        <span class="lineRowItem-content">{{deviceInfo? deviceInfo.device_id:''}}</span>
      </van-row>
      <van-row class="lineRowItemBottom">
        <span class="lineRowItem-title">设备地址</span>
        <span class="lineRowItem-contentAddress">{{deviceInfo? deviceInfo.address:''}}</span>
      </van-row>
    </van-row>
    <!-- 维护相关 -->
    <van-row class="bigRowTwo">
      <van-row class="lineTitle">
        <van-row class="lineTitle-left"></van-row>
        <van-row class="lineTitle-right">维护相关</van-row>
      </van-row>
      <van-row class="lineRow"></van-row>
      <van-row class="lineRowItem">
        <span class="lineRowItem-title">维护单位</span>
        <span class="lineRowItem-content">{{operationalInfo.maintenanceName}}</span>
      </van-row>
      <van-row class="lineRowItem">
        <span class="lineRowItem-title">联系人员</span>
        <span class="lineRowItem-content">{{operationalInfo.contact}}</span>
      </van-row>
      <van-row class="lineRowItemBottom">
        <span class="lineRowItem-title">联系电话</span>
        <span class="lineRowItem-content">{{operationalInfo.contactPhoneNumber}}</span>
      </van-row>
    </van-row>
    <!-- 工单流程 -->
    <van-row class="bigRowThree">
      <van-row class="lineTitle">
        <van-row class="lineTitle-left"></van-row>
        <van-row class="lineTitle-right">工单流程(已完成)</van-row>
      </van-row>
      <van-row class="lineRow"></van-row>
      <van-steps direction="vertical"
                 :active="4">
        <!-- 工单创建 -->
        <van-step>
          <van-row class="stepTitle">
            <span class="stepTitle-left">工单创建:</span>
            <span class="stepTitle-right">{{completedWork.ticketCreateTime}}</span>
          </van-row>
          <van-row class="lineOnlyOne">
            <span class="lineOnlyOne-title">工单编号:</span>
            <span class="lineOnlyOne-content">{{completedWork.workTicketSequence}}</span>
          </van-row>
          <van-row class="lineOnlyOne">
            <span class="lineOnlyOne-title">问题类型:</span>
            <span class="lineOnlyOne-content">{{completedWork.deviceFaultType}}</span>
          </van-row>
          <van-row class="lineHaveTwo">
            <span class="lineHaveTwo-title">问题描述:</span>
            <span class="lineHaveTwo-content">{{completedWork.deviceFaultDesc}}</span>
          </van-row>
          <van-row class="lineHaveTwo">
            <span class="lineHaveTwo-title">工单备注:</span>
            <span class="lineHaveTwo-content">{{completedWork.ticketRemark}}</span>
          </van-row>
        </van-step>
        <!-- 工单确认 -->
        <van-step>
          <van-row class="stepTitle">
            <span class="stepTitle-left">工单确认:</span>
            <span class="stepTitle-right">{{completedWork.ticketConfirmTime}}</span>
          </van-row>
          <van-row class="lineOnlyOne">
            <span class="lineOnlyOne-title">确认人员:</span>
            <span class="lineOnlyOne-content">{{completedWork.confirmorName}}</span>
          </van-row>
          <van-row class="lineHaveTwo">
            <span class="lineHaveTwo-title">确认备注:</span>
            <span class="lineHaveTwo-content">{{completedWork.ticketConfirmRemark}}</span>
          </van-row>
          <van-row class="linePic">
            <van-row class="linePic-title">确认图片:</van-row>
            <van-row class="linePic-content">
              <img style="width:100px;height:135px;"
                   :src="confirmPicUrl"
                   alt="">
            </van-row>
          </van-row>
        </van-step>
        <!-- 工单处理 -->
        <van-step>
          <van-row class="stepTitle">
            <span class="stepTitle-left">工单处理:</span>
            <span class="stepTitle-right">{{completedWork.dealtList? completedWork.dealtList.handleTime:''}}</span>
          </van-row>
          <van-row class="lineOnlyOne">
            <span class="lineOnlyOne-title">处理人员:</span>
            <span class="lineOnlyOne-content">{{completedWork.dealtList? completedWork.dealtList.handlerName:''}}</span>
          </van-row>
          <van-row class="lineHaveTwo">
            <span class="lineHaveTwo-title">处理备注:</span>
            <span class="lineHaveTwo-content">{{completedWork.dealtList? completedWork.dealtList.handleRemark:''}}</span>
          </van-row>
          <van-row class="linePic">
            <van-row class="linePic-title">确认图片:</van-row>
            <van-row class="linePic-content">
              <img style="width:100px;height:135px;"
                   :src="dealtPicUrl"
                   alt="">
            </van-row>
          </van-row>
        </van-step>
        <!-- 工单复核 -->
        <van-step>
          <van-row class="stepTitle">
            <span class="stepTitle-left">工单复核:</span>
            <span class="stepTitle-right">{{completedWork.checkList.checkerTime}}</span>
          </van-row>
          <van-row class="lineOnlyOne">
            <span class="lineOnlyOne-title">复核人员:</span>
            <span class="lineOnlyOne-content">{{completedWork.checkList.checkerName}}</span>
          </van-row>
          <van-row class="lineHaveTwo">
            <span class="lineHaveTwo-title">复核结果:</span>
            <span class="lineHaveTwo-content">{{completedWork.checkList.checkerRemark}}</span>
          </van-row>
        </van-step>
        <!-- 工单完成 -->
        <van-step>
          <van-row class="stepTitle">
            <span class="stepTitle-left">工单完成</span>
          </van-row>
        </van-step>
      </van-steps>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      deviceInfo: {}, // 设备信息
      deviceType: '', // 设备类型
      operationalInfo: {}, // 运维单位信息
      completedWork: {}, // 已完成工单
      confirmPicUrl: '',
      dealtPicUrl: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.deviceType = this.$route.query.deviceType
    this.queryDeviceInfo()
    this.queryOperational()
    this.queryWorkTicketList()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询工单信息
    queryWorkTicketList () {
      const info = {
        workTicketSequence: this.$route.query.workTicketSequence,
        pageNum: 1,
        pageSize: 1
      }
      this.$completedWork.queryWorkTicketList(info).then(res => {
        this.confirmPicUrl = this.$imgBaseUrl + res.resultEntity.list[0].picUrl
        this.queryWorkTicketDealt(res.resultEntity.list[0].workTicketDealtSequence)
        this.queryWorkTicketCheck(res.resultEntity.list[0].workTicketCheckSequence)
        this.completedWork = res.resultEntity.list[0]
      })
    },
    // 工单获取处理记录
    queryWorkTicketDealt (val) {
      const info = {
        workTicketDealtSequence: val
      }
      this.$completedWork.queryWorkTicketDealt(info).then(res => {
        this.completedWork.dealtList = res.resultEntity
        this.dealtPicUrl = this.$imgBaseUrl + res.resultEntity.picUrl
        this.$forceUpdate()
      })
    },
    // 工单获取复核记录
    queryWorkTicketCheck (val) {
      const info = {
        workTicketCheckSequence: val
      }
      this.$completedWork.queryWorkTicketCheck(info).then(res => {
        this.completedWork.checkList = res.resultEntity
        this.$forceUpdate()
      })
    },
    // 查询设备信息
    queryDeviceInfo () {
      const info = {
        deviceId: this.$route.query.deviceId,
        deviceType: this.$route.query.deviceTypeCode
      }
      this.$completedWork.queryDeviceInfo(info).then(res => {
        this.deviceInfo = res.resultEntity
      })
    },
    // 查询运维单位
    queryOperational () {
      const info = {
        maintenanceId: this.$route.query.maintenanceId
      }
      this.$completedWork.queryMaintenance(info).then(res => {
        this.operationalInfo = res.resultEntity
      })
    },
    // 顶部返回按钮
    handleClickBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.completedWorkDetails-mainBody {
  width: 100%;
  overflow-x: hidden;
  .bigRowOne {
    width: 93.6%;
    margin-left: 3.2%;
    margin-top: 12px;
    background: #fff;
    .lineTitle {
      width: 100%;
      height: 42px;
      line-height: 42px;
      display: flex;
      align-items: center;
      .lineTitle-left {
        width: 4px;
        height: 20px;
        background: #19a9fc;
        margin-left: 3.2%;
      }
      .lineTitle-right {
        font-family: PingFang-SC-Medium;
        font-size: 17px;
        color: #333333;
        margin-left: 1.8%;
      }
    }
    .lineRow {
      width: 93.6%;
      margin-left: 3.2%;
      height: 1px;
      background: #cfcfcf;
    }
    .lineRowItem {
      width: 93.6%;
      margin-left: 3.2%;
      height: 30px;
      line-height: 30px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      .lineRowItem-title {
        color: #909090;
      }
      .lineRowItem-content {
        margin-left: 5.2%;
        color: #333333;
      }
    }
    .lineRowItemBottom {
      width: 93.6%;
      margin-left: 3.2%;
      height: 60px;
      line-height: 30px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      margin-bottom: 10px;
      .lineRowItem-title {
        color: #909090;
      }
      .lineRowItem-content {
        margin-left: 5.2%;
        color: #333333;
      }
      .lineRowItem-contentAddress {
        margin-left: 5.2%;
        color: #333333;
      }
    }
  }
  .bigRowTwo {
    width: 93.6%;
    margin-left: 3.2%;
    margin-top: 12px;
    background: #fff;
    .lineTitle {
      width: 100%;
      height: 42px;
      line-height: 42px;
      display: flex;
      align-items: center;
      .lineTitle-left {
        width: 4px;
        height: 20px;
        background: #19a9fc;
        margin-left: 3.2%;
      }
      .lineTitle-right {
        font-family: PingFang-SC-Medium;
        font-size: 17px;
        color: #333333;
        margin-left: 1.8%;
      }
    }
    .lineRow {
      width: 93.6%;
      margin-left: 3.2%;
      height: 1px;
      background: #cfcfcf;
    }
    .lineRowItem {
      width: 93.6%;
      margin-left: 3.2%;
      height: 30px;
      line-height: 30px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      .lineRowItem-title {
        color: #909090;
      }
      .lineRowItem-content {
        margin-left: 5.2%;
        color: #333333;
      }
    }
    .lineRowItemBottom {
      width: 93.6%;
      margin-left: 3.2%;
      height: 30px;
      line-height: 30px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      margin-bottom: 10px;
      .lineRowItem-title {
        color: #909090;
      }
      .lineRowItem-content {
        margin-left: 5.2%;
        color: #333333;
      }
    }
  }
  .bigRowThree {
    width: 93.6%;
    margin-left: 3.2%;
    margin-top: 12px;
    margin-bottom: 30px;
    background: #fff;
    .lineTitle {
      width: 100%;
      height: 42px;
      line-height: 42px;
      display: flex;
      align-items: center;
      .lineTitle-left {
        width: 4px;
        height: 20px;
        background: #19a9fc;
        margin-left: 3.2%;
      }
      .lineTitle-right {
        font-family: PingFang-SC-Medium;
        font-size: 17px;
        color: #333333;
        margin-left: 1.8%;
      }
    }
    .lineRow {
      width: 93.6%;
      margin-left: 3.2%;
      height: 1px;
      background: #cfcfcf;
    }
    .stepTitle {
      width: 100%;
      height: 23px;
      line-height: 23px;
      display: flex;
      .stepTitle-left {
        font-family: PingFang-SC-Medium;
        font-size: 15px;
        color: #333333;
      }
      .stepTitle-right {
        font-family: PingFang-SC-Regular;
        font-size: 13px;
        color: #999999;
        position: absolute;
        float: right;
        right: 5%;
      }
    }
    .lineOnlyOne {
      width: 100%;
      height: 23px;
      line-height: 23px;
      margin-top: 5px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      .lineOnlyOne-title {
        color: #909090;
      }
      .lineOnlyOne-content {
        color: #333333;
        margin-left: 3.2%;
      }
    }
    .lineHaveTwo {
      width: 100%;
      height: 46px;
      line-height: 23px;
      margin-top: 5px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      .lineHaveTwo-title {
        color: #909090;
      }
      .lineHaveTwo-content {
        color: #333333;
        margin-left: 3.2%;
      }
    }
    .linePic {
      width: 100%;
      height: 140px;
      line-height: 23px;
      margin-top: 5px;
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      display: flex;
      .linePic-title {
        color: #909090;
      }
      .linePic-content {
        color: #333333;
        margin-left: 3.2%;
      }
    }
  }
}
</style>
